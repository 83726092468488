//获取场地类型列表(分页)
const getPlaceTypeListPagingUrl = `/gateway/hc-serve/manageapi/placeType/placeTypeListPaging`;
//获取场地大类型
const getPlaceTypeUrl = `/gateway/hc-serve/manageapi/placeType/placeTypeCategoryList`;
//新增/修改 场地类型
const editPlaceTypeUrl = `/gateway/hc-serve/manageapi/placeType/editPlaceType`;
//删除场地类型
const delPlaceTypeUrl = `/gateway/hc-serve/manageapi/placeType/delPlaceType`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
//获取场地类型列表(不分页)
const getPlaceTypeListUrl = `/gateway/hc-serve/manageapi/placeType/placeTypeList`;

//获取场地列表
const placeListPagingUrl = `/gateway/hc-serve/manageapi/place/listPaging`;
//根据场地ID获取场地详情信息
const placeDetailByIdUrl = `/gateway/hc-serve/manageapi/place/detailById`;
//新增/修改 场地
const editPlaceUrl = `/gateway/hc-serve/manageapi/place/updPlace`;
//开启/关闭/删除 场地
const changePlaceStatus = `/gateway/hc-serve/manageapi/place/changePlaceStatus`;

//获取场地订单列表
const placeOrderListPagingUrl = `/gateway/hc-serve/manageapi/place/order/listPaging`;
//根据场地ID获取场地详情信息
const placeOrderDetailByIdUrl = `/gateway/hc-serve/manageapi/place/order/detailById`;
//取消用户的预约订单
const placeOrderCancelUrl = `/gateway/hc-serve/manageapi/place/order/cancelOrder`;
//确认发送短信预约订单
const sureOrderCancelUrl = `/gateway/hc-serve/manageapi/place/order/confirmOrder`;
//获取一级分类
const getFirstKindsUrl = `/gateway/hc-serve/manageapi/place/order/confirmOrder`;
//场所详情
const getRentDetailUrl = `/gateway/hc-space/space/detail`;

// 获取区县/街道/社区列表
const getStreetCommunityListUrl = `/gateway/hc-serve/manageapi/place/get-street-community-list`;
// 精准获取区县/街道/社区
const getCommunityListByTenantIdUrl = `/gateway/hc-serve/manageapi/place/getCommunityListByTenantId`;
//场次列表
const getSessionUrl = `/gateway/hc-serve/miniapi/reservation/placeScheduleList`;
//预约场次
const getAppointUrl = `/gateway/hc-serve/miniapi/reservation/addOrder`;
//驳回
const rejectOrderUrl = `/gateway/hc-serve/manageapi/place/order/rejectOrder`;
export {
  getPlaceTypeListPagingUrl,
  editPlaceTypeUrl,
  delPlaceTypeUrl,
  getCommunityListUrl,
  placeListPagingUrl,
  placeDetailByIdUrl,
  editPlaceUrl,
  changePlaceStatus,
  getPlaceTypeListUrl,
  placeOrderListPagingUrl,
  placeOrderDetailByIdUrl,
  placeOrderCancelUrl,
  getPlaceTypeUrl,
  sureOrderCancelUrl,
  getFirstKindsUrl,
  getRentDetailUrl,
  getStreetCommunityListUrl,
  getCommunityListByTenantIdUrl,
  getSessionUrl,
  getAppointUrl,
  rejectOrderUrl,
};
